import { ErrorResponse, Product } from '../../models/og/menu'
import { NEXTJS_API_PATH, WWW_BASE_URL } from '../../utils/constants'

// Components will use this function to make client-side requests to /api/v2/items
export default async function getClientSideItem(request): Promise<{ err: ErrorResponse; data }> {
  let result
  let statusCode: number

  const apiRoute = buildURL(request)

  try {
    const response = await fetch(apiRoute)
    statusCode = response.status
    result = await response.json()
  } catch (err) {
    console.error(`Error fetching item ${JSON.stringify(request)}`, err)
    return { err: { message: err?.message, statusCode } as ErrorResponse, data: null }
  }
  if ('message' in result) {
    return { err: { message: result.message, statusCode }, data: null }
  }
  return { err: null, data: result as Product }
}

function buildURL(request): URL {
  const apiRoute = new URL(`${NEXTJS_API_PATH}/items/${request.id}`, WWW_BASE_URL)
  if (request?.placeId) apiRoute.searchParams.append('placeId', request.placeId)
  if (request?.menuSlug) apiRoute.searchParams.append('menuSlug', request.menuSlug)
  return apiRoute
}
